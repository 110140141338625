import { InformationCircleIcon } from '@heroicons/react/outline'
import { useState, useEffect } from 'react'
import { Alert } from './components/alerts/Alert'
import { Grid } from './components/grid/Grid'
import { Keyboard } from './components/keyboard/Keyboard'
import { AboutModal } from './components/modals/AboutModal'
import { InfoModal } from './components/modals/InfoModal'
import { WinModal } from './components/modals/WinModal'
import { isWinningWord, solution, hint } from './lib/words'
import {
  loadGameStateFromLocalStorage,
  saveGameStateToLocalStorage,
} from './lib/localStorage'
declare var window: any

function App() {
  const [currentGuess, setCurrentGuess] = useState('')
  const [isGameWon, setIsGameWon] = useState(false)
  const [isWinModalOpen, setIsWinModalOpen] = useState(false)
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false)
  const [isAboutModalOpen, setIsAboutModalOpen] = useState(false)
  const [isGameLost, setIsGameLost] = useState(false)
  const [shareComplete, setShareComplete] = useState(false)
  const [guesses, setGuesses] = useState<string[]>(() => {
    const loaded = loadGameStateFromLocalStorage()
    if (loaded?.solution !== solution) {
      return []
    }
    if (loaded.guesses.includes(solution)) {
      setIsGameWon(true)
      setTimeout(() => {
        if(window.OneSignal && window.OneSignal.length!==0) {
          window.OneSignal.showSlidedownPrompt()
        }
      }, 2000);
    } 
    return loaded.guesses
  })


  useEffect(() => {
    if(guesses.length === 0 && hint!=='') {
      setGuesses([...guesses, hint])
      setCurrentGuess('')
    }
  }, [guesses])

      

  useEffect(() => {
    saveGameStateToLocalStorage({ guesses, solution })
  }, [guesses])

  useEffect(() => {
    if (isGameWon) {
      setIsWinModalOpen(true)
    }
  }, [isGameWon])

  const onChar = (value: string) => {
    if (currentGuess.length < solution.length && guesses.length < 6) {
      setCurrentGuess(`${currentGuess}${value}`)
    }
  }

  const onDelete = () => {
    setCurrentGuess(currentGuess.slice(0, -1))
  }

  const onEnter = () => {
    /*
    if (!isWordInWordList(currentGuess)) {
      setIsWordNotFoundAlertOpen(true)
      return setTimeout(() => {
        setIsWordNotFoundAlertOpen(false)
      }, 2000)
    }
    */

    const winningWord = isWinningWord(currentGuess)

    if (currentGuess.length === solution.length && guesses.length < 6 && !isGameWon) {
      setGuesses([...guesses, currentGuess])
      setCurrentGuess('')
      if(window.mixpanel) {
        window.mixpanel.track('Try', {'word':currentGuess});
        sendHTPMessage('start');
      }
      

      if (winningWord) {
        if(window.mixpanel) {
          window.mixpanel.track('Won', {'word':currentGuess, 'tries':guesses.length})
        }
        if(window.OneSignal && window.OneSignal.length!==0) {
          window.OneSignal.showSlidedownPrompt()
        }
        sendHTPMessage('success');
        return setIsGameWon(true)
      }

      if (guesses.length === 5) {
        if(window.mixpanel) {
          window.mixpanel.track('Lost', {'word':currentGuess})
        }
        if(window.OneSignal && window.OneSignal.length!==0) {
          window.OneSignal.showSlidedownPrompt()
        }
        sendHTPMessage('failed');
        setIsGameLost(true)
        return setTimeout(() => {
          setIsGameLost(false)
        }, 2000)
      }
    }
  }

  function sendHTPMessage(event) {
    const token = new URL(window.location)?.searchParams?.get('htp-token') ?? window.localStorage.getItem('htp-token');
    if (token) {
        window.localStorage.setItem('htp-token', token);
        const startDate = Date.parse('January 28, 2022 00:00:00');
        const now = Date.now();
        const msInWeek = 604800000;
        const currentDayIndex = Math.floor((now - startDate) / msInWeek) 
        let message = {
            unique: currentDayIndex,
            game: 'boardle',
            event: event,
            type: 'boardiacs-event',
            token: token
        };
        //console.log(message);
        var iframe = (document?.querySelector('#htp-integration') as HTMLIFrameElement);
        iframe.contentWindow?.postMessage(message,'*');
    }
  }

  return (
    <div className={`py-8 max-w-7xl mx-auto sm:px-6 lg:px-8 ${isWinModalOpen ? "hideLetters" : ""}`}>
      <Alert
        message={`You lost, the word was ${solution}`}
        isOpen={isGameLost}
      />
      <Alert
        message="Game copied to clipboard"
        isOpen={shareComplete}
        variant="success"
      />
      <div className="flex w-80 mx-auto items-center mb-8">
        <h1 className="text-xl font-bold">Boardle</h1>
        <h3 className="text-sm grow px-2 pt-1">by <a className="font-bold" href="https://instagram.com/boardiacs">@boardiacs</a></h3>
        <InformationCircleIcon
          className="h-6 w-6 cursor-pointer"
          onClick={() => setIsInfoModalOpen(true)}
        />
      </div>
      <Grid guesses={guesses} currentGuess={currentGuess} />
      <Keyboard
        onChar={onChar}
        onDelete={onDelete}
        onEnter={onEnter}
        guesses={guesses}
      />
      <WinModal
        isOpen={isWinModalOpen}
        handleClose={() => setIsWinModalOpen(false)}
        guesses={guesses}
        handleShare={() => {
          //setIsWinModalOpen(false)
          setShareComplete(true)
          return setTimeout(() => {
            setShareComplete(false)
          }, 2000)
        }}
      />
      <InfoModal
        isOpen={isInfoModalOpen}
        handleClose={() => {
          if(window.mixpanel) {
            window.mixpanel.track('Info')
          }
          setIsInfoModalOpen(false)
        }}
      />
      <AboutModal
        isOpen={isAboutModalOpen}
        handleClose={() => setIsAboutModalOpen(false)}
      />

      <button
        type="button"
        className="mx-auto mt-8 flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        onClick={() => {
          if(window.mixpanel) {
            window.mixpanel.track('About')
          }
          setIsAboutModalOpen(true)
        }}
      >
        About this game
      </button>
    </div>
  )
}

export default App
