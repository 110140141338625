import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/outline'
import { MiniGrid } from '../mini-grid/MiniGrid'
import { XCircleIcon } from '@heroicons/react/outline'
import { solutionIndex } from '../../lib/words'
import { shareStatus } from '../../lib/share'
import { useState } from 'react'
declare var window: any

type Props = {
  isOpen: boolean
  handleClose: () => void
  guesses: string[]
  handleShare: () => void
}

export const WinModal = ({
  isOpen,
  handleClose,
  guesses,
  handleShare,
}: Props) => {
  const [isShared, setIsShared] = useState(false)
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={handleClose}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          
          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
              <div className="absolute right-4 top-4">
                <XCircleIcon
                  className="h-6 w-6 cursor-pointer"
                  onClick={() => handleClose()}
                />
              </div>
              <div>
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                  <CheckIcon
                    className="h-6 w-6 text-green-600"
                    aria-hidden="true"
                  />
                </div>
                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-gray-900"
                  >
                    Congratulations! You've won this week's <span className="font-bold">BOARDLE</span> (#{solutionIndex + 1})!
                  </Dialog.Title>
                  <div className="mt-2">
                    <MiniGrid guesses={guesses} />
                    <p className="text-sm text-gray-500" style={{ display: (isShared ? 'none' : 'block') }}>
                      If you press the button below your result will be copied to the clipboard and you can screenshot your results to share your success on instagram, don't forget to tag {' '}
                      <a 
                        href="https://instagram.com/boardiacs" 
                        className="font-bold"
                      >
                        @boardiacs
                      </a>! 
                    </p>
                    <p className="text-sm text-gray-500" style={{ display: (isShared ? 'block' : 'none') }}>
                      You've guessed the <span className="font-bold">boardgame</span> related word correctly with {6 - guesses.length} guesses left! :)<br/>
                      Looking forward to next week's game! <br/> A new Boardle (#{solutionIndex + 2}) will be available starting <span className="font-bold">Thursday night at midnight</span> in your local timezone.<br/>
                      <span className="text-3xl text-slate-200 float-right">@boardiacs</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6" style={{ display: (isShared ? 'none' : 'block') }}>
                <button
                  type="button"
                  className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                  onClick={() => {
                    if(window.mixpanel) {
                      window.mixpanel.track('Share')
                    }
                    setIsShared(true)
                    shareStatus(guesses)
                    handleShare()
                  }}
                >
                  Share
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
