import { getGuessStatuses } from './statuses'
import { solutionIndex, hint } from './words'

export const shareStatus = (guesses: string[]) => {
    let text = 'Boardle #' + (solutionIndex+1) 
    if(hint!=='') {
      text += ' ' + (guesses.length-1) + '/5\n'
    } else {
      text += ' ' + guesses.length + '/6\n'
    }
  navigator.clipboard.writeText(
    text + generateEmojiGrid(guesses)
  )
}

export const generateEmojiGrid = (guesses: string[]) => {
  return guesses
    .map((guess, index) => {
      if(index===0 && hint!=='') {
        return 'Hint of the week: '+ hint + '\n'
      }
      const status = getGuessStatuses(guess)
      return guess
        .split('')
        .map((letter, i) => {
          switch (status[i]) {
            case 'correct':
              return '🟩'
            case 'present':
              return '🟨'
            default:
              return '⬜'
          }
        })
        .join('')
    })
    .join('\n')
}
