import { WORDS, HINTS } from '../constants/wordlist'

export const isWinningWord = (word: string) => {
  return solution === word
}

export const getWordOfDay = () => {

  const startDate = Date.parse('January 28, 2022 00:00:00');
  const now = Date.now();

  const msInWeek = 604800000;
  const index = Math.floor((now - startDate) / msInWeek) 

  return {
    solution: WORDS[index].toUpperCase(),
    solutionIndex: index,
    hint: HINTS[index].toUpperCase()
  }
}

export const { solution, solutionIndex, hint } = getWordOfDay()
